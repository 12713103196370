<template>
  <div class="iot-extended public-page-style">
    <div class="iot-extended-title">功能中心</div>
    <el-card
      style="width: 30%; display: inline-block; margin: 20px"
      v-for="item in tableData"
      :key="item.menuId"
    >
      <div slot="header" class="iot-extended-header">
        <span>{{ item.menuName }}</span>
      </div>
      <div
        class="iot-extended-icon"
       
      >
      <img style="width:100%" :src="constant.SYSTEM_IMG +item.menuImg " alt="">
        <!-- <i v-else style="font-size: 40px" class="iconfont">{{
          item.icon || "&#xe67d;"
        }}</i> -->
      </div>
      <p class="iot-extended-remark">
        {{ item.remark }}
      </p>
      <el-button
        v-if="item.menuState == 1"
        type="primary"
        size="mini"
        class="iot-extended-btn"
        @click="opening(item.menuId,0)"
        >开 通</el-button
      >
      <el-button
        v-else-if="item.menuState == 0"
        type="info"
        size="mini"
        class="iot-extended-btn"
        > 已开通</el-button
      >
      <el-button
        v-else-if="item.menuState == 2"
      
        type="info"
        size="mini"
        disabled
        class="iot-extended-btn btn"
        >敬请期待</el-button
      >
    </el-card>
    <div class="iot-extended-page" style="width: 100%">
      <el-pagination
        style="float: right"
        background
      :current-page="getForm.current"
        :total="total"
        :page-size="getForm.size"
        layout="total,  prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
        @current-change="
          (v) => {
            $set(getForm, 'current', v);
            getList();
          }
        "
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
let that;
import constant from "../util/constant";
import { addDynamicMenuRoutes} from '../util/guardTool';
export default {
  name: "ExtendedServices",
  data() {
    return {
      constant: constant,
      getForm: {
        size: 6,
        current: 1,
      },
      tableData: [],
      total: 0,
    };
  },
  methods: {
    //获取导航菜单
    ruleApi(){
      that.$api.pub.ruleApi().then(response => {
        console.log(response);
        that.$store.commit('setRoutes', addDynamicMenuRoutes(response.data));
            
        that.$store.commit('setTreeList', response);
        //  that.$router.push('/extendedService/functionCenter');
      })
    },
    getList() {
      that.$api.extended.modulesList(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.total = response.data.total;
      });
    },
    opening: function (menuId,state) {
      let forms={
        "menuId":menuId,
        "state":state
      }
      that.$api.menuManagement.postOpen(forms).then((res)=>{
        if (res.code==200) {
          that.getList();
          that.ruleApi();
         
        }
      })
    },
  },
  beforeMount: function () {
    that = this;
  },
  mounted: function () {
    that.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-extended{
  position: relative;
}
.iot-extended-title{
  line-height: 40px;
  font-size: 18px;
  margin: 10px 0;
}
.iot-extended /deep/ .el-card__header {
  background-color: rgb(78,115,223);
  height: 40px;
  padding: 0;
  line-height: 40px;
  text-align: center;
  color: #fff;
}
.iot-extended-header{
  font-weight: 550;
  font-size: 16px;
}
.iot-extended-icon {
  width: 100px;
  height: 100px;
  margin: 10px auto;
  border-radius: 50px;
  /* background-color: #e592ff; */
  margin-left: calc(50% - 50px);
  text-align: center;
  line-height: 100px;
  color: #ffffff;
 
}
.iot-extended-remark {
  width: 80%;
  margin-left: 10%;
  font-size: 14px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  min-height: 40px;
}
.iot-extended-btn {
  float: right;
  margin-bottom: 15px;
}
.iot-extended-page{
  box-sizing: border-box;
  padding: 0 20px;
  position: absolute;
  bottom: 60px;
  left: 0;
}
::v-deep .btn{
  background-color: rgb(225,1,127);
}
</style>
